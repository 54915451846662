import React, { useRef } from 'react';
import styled from "styled-components";


const SoundOnHoverStyle = styled.span`
    span{
        padding: 0 0 4px;
        background: url(https://d35aaqx5ub95lt.cloudfront.net/images/06f94a15de0c0937cce25dc5dc083e6e.svg) 0 100% repeat-x;
    }
    cursor: default;
`

const defaultConfig = {
    stopSoundOnMouseLeave: false,
}
function SoundOnHover({ soundUrl, displayText, config = defaultConfig}) {
    const audioRef = useRef(null);

    const playSound = () => {
        audioRef.current.play();
    };

    const stopSound = () => {
        if(config.stopSoundOnMouseLeave){
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    return (
        <SoundOnHoverStyle>
            <span onMouseEnter={playSound} onMouseLeave={stopSound} >
                {displayText}
            </span>
            <audio ref={audioRef} src={soundUrl} preload="auto" />
        </SoundOnHoverStyle>
    );
}

export default SoundOnHover;

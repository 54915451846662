import React,{useState, useEffect} from "react";
import StorageService from "../services/storageService";
import styled from "styled-components";
import gearpaw from "../assets/gearpaw.png";
import {HoverButton} from "./UI";
/**
    config properties supported types:
    number
**/

const PawImg = styled.img`
width:48px;
`
export const OpenConfigMenuButton = () => {
   return <HoverButton popovertarget="modal" ><PawImg src={gearpaw} alt="gear-monkey-paw"/></HoverButton>
}

const NumberInput = ({quizId,configProperty,config}) => {
    const [number, setNumber] = useState(config[configProperty]);
    const handleChange = (event) => {
        setNumber(event.target.valueAsNumber);
    }
    useEffect(() => {
        config[configProperty] = number;
        StorageService.setQuizConfig(quizId,config)
    }, [number]);
    return <p>{configProperty}
        <input onChange={handleChange} type="number" value={number} id={configProperty} name={configProperty} min="0" max="100"/>
    </p>
}

const DropdownSelect = ({quizId,configProperty,config})  => {
    const [selectedOption, setSelectedOption] = useState(config[configProperty].selectedOption);  // Initialize the state with a default value

    const handleChange = (event) => {
        setSelectedOption(event.target.value);  // Update the state to reflect the new selected option
    };

    useEffect(() => {
        config[configProperty].selectedOption = selectedOption;
        StorageService.setQuizConfig(quizId,config)
    }, [selectedOption]);

    return (
        <p>{configProperty}
            <select value={selectedOption} onChange={handleChange}>
                {config[configProperty].options.map((option,index) => {
                   return <option key={index} value={option}>{option}</option>
                })}
            </select>
        </p>
    );
}



const QuizConfigStyle = styled.section`
    display: flex;
    justify-content: space-between;
    width: 70vw;
    height: 70vh;

`



const QuizConfig = ({quizId}) => {
    const config = StorageService.getQuizConfig(quizId)
    return (
        <QuizConfigStyle>
            { config ? <section  >
            <HoverButton popovertarget="modal" >X</HoverButton>
            {Object.keys(config).map((configProperty,index)=> {
                if(typeof config[configProperty] === 'number'){
                   return <NumberInput key={index} quizId={quizId} configProperty={configProperty} config={config}/>
                }else if(typeof config[configProperty] === 'object' && config[configProperty].options){
                    return <DropdownSelect key={index} quizId={quizId} configProperty={configProperty} config={config}/>;
                }
                return <></>
                }
            )}
        </section> : <section>
                <HoverButton popovertarget="modal" >X</HoverButton>
                <h1>there are no config options for this quiz yet :(</h1>
            </section>
            }

        </QuizConfigStyle>
    )

}

export default QuizConfig

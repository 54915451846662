import MultipleChoice from "../../classes/MultipleChoice";
import {convertBase, generateRandomBaseNumber} from "../../utils";
import StorageService from "../../services/storageService";

export const quizId = 'nmbrbasecnvrsn-mltplchce'; //magic string

const defaultConfig = {
    numberOfDigits: 4,
    fromRadix: 2,
    toRadix: 10,
    numberDisplayStyle: {
        selectedOption: 'math',
        options: ['math','programming']
    }
}

if(!StorageService.getQuizConfig(quizId)){ //initialize config if it's not existing in storage
    StorageService.setQuizConfig(quizId,defaultConfig)
}
const generateBaseConversionQuestion = () => {
    let config = StorageService.getQuizConfig(quizId) || defaultConfig;
    const {fromRadix, numberOfDigits,toRadix} = config;
    let numStr = generateRandomBaseNumber(fromRadix,numberOfDigits);

    return { number: numStr, fromRadix, numberOfDigits , toRadix }
}

const solveBaseConversionQuestion = (question) => {
    const {number,fromRadix,toRadix} = question
    const convertedNumber = convertBase(number, fromRadix,toRadix)
    return { convertedNumber, number, fromRadix, toRadix }
}

const baseConversionQuiz = new MultipleChoice(generateBaseConversionQuestion,solveBaseConversionQuestion)

export default baseConversionQuiz;

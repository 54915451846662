import React from "react";
import Lesson from "../components/Lesson";
import {arrayRandomIndex} from "../utils";
import GreekAlphabetLetterNameToUppercaseLetter from "../quizzes/linguistics/GreekAlphabetLetterNameToUppercaseLetter.jsx";
import GreekAlphabetUppercaseLetterToLowercaseLetterQuiz from "../quizzes/linguistics/GreekAlphabetUppercaseLetterToLowercaseLetterQuiz.jsx";
const GreekLesson = () => {
    return <Lesson getNextQuizComponent={() => [GreekAlphabetLetterNameToUppercaseLetter, GreekAlphabetUppercaseLetterToLowercaseLetterQuiz][arrayRandomIndex(2)]}/>
}

export default GreekLesson;

import {createContext} from "react";
import BaseConversionQuiz from "./positionalNotation/NumberBaseConversionQuiz.jsx";
import {quizId as numberBaseConversionQuizId} from "./positionalNotation/numberBaseConversionQuiz.js";
import {quizId as exponentsQuizId} from "./Arithmetic/exponentsQuiz.js";
import ExponentsQuiz from "./Arithmetic/ExponentsQuiz.jsx";

export const quizzes = {
    [numberBaseConversionQuizId]: BaseConversionQuiz,
    [exponentsQuizId]: ExponentsQuiz,
}


export const QuizzesContext = createContext(quizzes);

import React from "react";
import Lesson from "../components/Lesson";
import {arrayRandomIndex} from "../utils";
import ExponentsQuiz from "../quizzes/Arithmetic/ExponentsQuiz.jsx";

const ExponentsLesson = () => {
    return <Lesson getNextQuizComponent={() => [ExponentsQuiz][arrayRandomIndex(1)]}/>
}

export default ExponentsLesson;

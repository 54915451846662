import React from 'react';
import MultipleChoiceQuiz2 from "../../components/MultipleChoiceQuiz2";
import greekAlphabetUppercaseToLowercase,{quizId} from "./GreekAlphabetUppercaseLetterToLowercaseLetterQuiz.js";
import SoundOnHover from "../../components/SoundOnHover";
import {greekAlphabetLetterToSoundUrlMap} from './greekAlphabet.js'

//display logic
const lowercaseToUppercaseQuestionDisplay = (question) => {
   return (<>
      <h4> transform to lowercase:</h4>
      <h1> {<SoundOnHover soundUrl={greekAlphabetLetterToSoundUrlMap[question]} displayText={question} />}</h1>
   </>)
}

const lowercaseToUppercaseAnswerDisplay = (answer) => {
   return <span>{answer}</span>
}

//all the 'quiz' object is passed to the explanation function so we can stay dynamic with what we want to write in explanation
const lowercaseToUppercaseExplanation = ({correctAnswer}) => {
   return <span>the correct answer is {correctAnswer}</span>
}

const GreekAlphabetNameToCapitalLetter = () => {
   return <MultipleChoiceQuiz2
          quizId={quizId}
          quiz={greekAlphabetUppercaseToLowercase}
          questionDisplay={lowercaseToUppercaseQuestionDisplay}
          answerDisplay={lowercaseToUppercaseAnswerDisplay}
          explanationDisplay={lowercaseToUppercaseExplanation}
      />
}

export default GreekAlphabetNameToCapitalLetter;

import {Link} from "react-router-dom";
import {HoverButton} from "./UI";
import monkeyArrow from "../assets/monkey-arrow.png";
import React from "react";
import styled from "styled-components";


const  NavigateToHomePageButtonStyle = styled.span`
    display: flex;
    align-items: center;
img{
    width: 52px;
}
`
const NavigateToHomePageButton = () => {
    return  <NavigateToHomePageButtonStyle>
        <Link to="/">
            <HoverButton>
                <img src={monkeyArrow} alt="gear-monkey-paw"/>
            </HoverButton>
        </Link>
    </NavigateToHomePageButtonStyle>
}

export default NavigateToHomePageButton

import styled from "styled-components";
export const Button = styled.button`
    display: inline-block;
    margin: 4px 2px;
    padding: 10px 20px;
    background-color: white;
    color: #232222;
    border: 2px solid rgba(229,229,229,1);
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    box-shadow: 0 2px 0 rgba(229,229,229,1);
    border-radius: 16px;
    cursor: pointer;
    &:active{
        transform: translateY(2px);
        box-shadow: 0 0 0;
    }
`

export const ButtonFocus = styled(Button)`
    &:hover{
        background-color: rgb(247,247,247)
    }
    &:focus, &.focus-like{
        background-color:rgb(221 244 255);
        border-color: rgb(132 216 255);
        box-shadow: 0 2px 0 rgb(132 216 255);
    }
    &:active{
        transform: translateY(0px);
        box-shadow: 0 0 0;
    }
`

export const CheckButton = styled(Button)`
    color: white;
    border: none;
    background-color: rgb( 60,77,255 );
    box-shadow: 0 5px 0 #3f23ec;
    &.user-correct{
        background-color: rgb(88,204,2);
        box-shadow: 0 5px 0 rgb(88,167,0);
    }
    &.user-wrong{
        background-color: rgb(255 75 75);
        box-shadow: 0 5px 0 rgb(234 42 42);
    }
    &:active{
        transform: translateY(5px);
        box-shadow: 0 0 0;
    }
    &:disabled{
        background-color:rgb(229 229 229);
        color:rgb(175 175 175);
        box-shadow: none;
        transform: translateY(5px);
    }

`


export const PrimaryButton = styled(Button)`
    color: white;
    //legacy colors 
    //background-color: rgb( 60,77,255 );
    //box-shadow: 0 5px 0 #3f23ec;
    font-weight:bold;
    background-color: rgb(60 130 255 / 65%);
    box-shadow: 0 5px 0 #2389eceb;
    border: none;
    &:active{
        transform: translateY(5px);
        box-shadow: 0 0 0;
    }
`

export const HoverButton = styled.button`
    display: inline-block;
    background-color: white;
    color: #232222;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 16px;
    cursor: pointer;
    border: none;

    &:hover{
        background-color:rgb(247,247,247);
    }
`


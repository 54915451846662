import React from 'react';
import MultipleChoiceQuiz2 from "../../components/MultipleChoiceQuiz2.jsx";
import greekAlphabetLetterNameToUppercaseLetter,{quizId} from "./GreekAlphabetLetterNameToUppercaseLetter.js";
import SoundOnHover from "../../components/SoundOnHover";
import {greekAlphabetLetterToSoundUrlMap} from './greekAlphabet.js'

//display logic
const letterNameToUppercaseQuestionDisplay = (question) => {
   return (<>
      <h4> choose: </h4>
      <h1> {<SoundOnHover soundUrl={greekAlphabetLetterToSoundUrlMap[question]} displayText={question}/>}</h1>
   </>)
}

const letterNameToUppercaseAnswerDisplay = (answer) => {
   return <span>{answer}</span>
}

//all the 'quiz' object is passed to the explanation function so we can stay dynamic with what we want to write in explanation
const letterNameToUppercaseExplanation = ({correctAnswer}) => {
   return <span>the correct answer is {correctAnswer}</span>
}

const GreekAlphabetNameToCapitalLetter = () => {
   return <MultipleChoiceQuiz2
          quizId={quizId}
          quiz={greekAlphabetLetterNameToUppercaseLetter}
          questionDisplay={letterNameToUppercaseQuestionDisplay}
          answerDisplay={letterNameToUppercaseAnswerDisplay}
          explanationDisplay={letterNameToUppercaseExplanation}
      />
}

export default GreekAlphabetNameToCapitalLetter;

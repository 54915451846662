import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '../components/UI';
import shlyphi from '../assets/shlyphi.png';
import shlyphiBlue from '../assets/shlyphi-blue.png';

const HomePage = styled.div`
    display: grid;
    justify-items: center;
    
    img{
        width: 294px;
    }
    nav {
        order: 1;
        button{
            display: block;
            width: 300px;
            margin: 23px 0;
            padding: 20px;
        }
        a{
            display: block;
            color: inherit;
            text-decoration: initial;
        }
    }
`

function Home() {
    let imgSrc;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        //development code
        imgSrc = shlyphiBlue;
    } else {
        // production code
        imgSrc = shlyphi;

    }
  return (
      <HomePage>
          <img src={imgSrc} alt="red-handed tamarin mascot"/>
          <nav>
              <Link to="/history">
                  <PrimaryButton>Centuries</PrimaryButton>
              </Link>
              <Link to="/exponents">
                  <PrimaryButton>
                      Exponents
                  </PrimaryButton>
              </Link>
              <Link to="/base-conversion">
                  <PrimaryButton>
                      Base Conversions
                  </PrimaryButton>
              </Link>
              {/*<Link to="/positional-number-systems">*/}
              {/*    <PrimaryButton>*/}
              {/*        Positional number systems*/}
              {/*    </PrimaryButton>*/}
              {/*</Link>*/}
              <Link to="/zodiac-to-symbol">
                  <PrimaryButton>
                      Zodiac to symbol
                  </PrimaryButton>
              </Link>
              <Link to="/greek">
                  <PrimaryButton>
                      Greek Alphabet
                  </PrimaryButton>
              </Link>
              <Link to="/month-number">
                  <PrimaryButton>
                      month name to month number
                  </PrimaryButton>
              </Link>
          </nav>
          <main>
              <h1>Welcome to Shlyph!</h1>
          </main>

      </HomePage>
  );
}

export default Home;

import React from 'react';
import styled from "styled-components";
import MultipleChoiceQuiz2 from "../../components/MultipleChoiceQuiz2";
import yearToCentury from "./yearToCenturyQuiz.js";
import {numberToOrdinal} from "../../utils";
import {quizId} from "./yearToCenturyQuiz.js";

const ADBCSpan = styled.span`
    &.ad {
        color: #19a878;
    }

    &.bc {
        color: #bb6729;
    }

`
const exponentQuestionDisplay = (question) => {
    const styleClass = question.era === 'AD' ? 'ad': 'bc'
    return <h1>choose the correct century for the year <ADBCSpan className={styleClass}> {question.year} {question.era}</ADBCSpan></h1>
}

const answerDisplay = (answer) => {
    const styleClass = answer.era === 'AD' ? 'ad': 'bc'
    return <ADBCSpan className={styleClass}>{numberToOrdinal(answer.century)} {answer.era}</ADBCSpan>
}
const yearToCenturyExplanationDisplay = ({correctAnswer}) => {
    return <ADBCSpan>the correct answer is {correctAnswer.century} {correctAnswer.era}</ADBCSpan>
}

const YearToCenturyQuiz = () => {
    return <MultipleChoiceQuiz2
            quizId={quizId}
            quiz={yearToCentury}
            questionDisplay={exponentQuestionDisplay}
            answerDisplay={answerDisplay}
            explanationDisplay={yearToCenturyExplanationDisplay}

        />

}

export default YearToCenturyQuiz;

import StorageService from "../../services/storageService";
import MultipleChoice from "../../classes/MultipleChoice";
import {greekAlphabetNameToCapitalLetterMap} from "./greekAlphabet";

export const quizId = 'grklttrnmtlphbtlttr-mltplchce'; //magic string

const defaultConfig = {
    letterRange: 24, // not doing anything right now
}

if(!StorageService.getQuizConfig(quizId)){ //initialize config if it's not existing in storage
    StorageService.setQuizConfig(quizId,defaultConfig)
}

const greekAlphabetLetterNameToUppercaseLetter = new MultipleChoice(greekAlphabetNameToCapitalLetterMap)

export default greekAlphabetLetterNameToUppercaseLetter;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from 'react-router-dom'; // Import HashRouter, Routes, and Route
import Home from './pages/Home';
import { createGlobalStyle } from 'styled-components';
import MonthNameToMonthNumberQuiz from './quizzes/dateAndTime/MonthNameToMonthNumberQuiz.jsx';
import ZodiaqSignToSymbolQuiz2 from "./quizzes/dateAndTime/ZodiaqSignToSymbolQuiz2.jsx";
import PositionalNumberSystems from "./pages/PositionalNumberSystems";
import {quizzes, QuizzesContext} from "./quizzes/quizzesContext";
import GreekLesson from "./lessons/GreekLesson";
import HistoryYearLesson from "./lessons/HistoryYearLesson";
import ExponentsLesson from "./lessons/ExponentsLesson";
import BaseConversionsLesson from "./lessons/BaseConversionsLesson";

const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
    }
    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QuizzesContext.Provider value={quizzes}>
        <GlobalStyle />
        <HashRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/history" element={<HistoryYearLesson />} />
                <Route path="/month-number" element={<MonthNameToMonthNumberQuiz />} />
                <Route path="/base-conversion" element={<BaseConversionsLesson />} />
                <Route path="/zodiac-to-symbol" element={<ZodiaqSignToSymbolQuiz2 />} />
                <Route path="/greek" element={<GreekLesson />} />
                <Route path="/exponents" element={<ExponentsLesson />} />
                <Route path="/positional-number-systems" element={<PositionalNumberSystems />} />
            </Routes>
        </HashRouter>
    </QuizzesContext.Provider>
);

import StorageService from "../../services/storageService";
import MultipleChoice from "../../classes/MultipleChoice";

export const quizId = 'mnthnmetonumber-mltplchce'; //magic string

const defaultConfig = {
    numberOfMonths: 12, // not doing anything right now
}

if(!StorageService.getQuizConfig(quizId)){ //initialize config if it's not existing in storage
    StorageService.setQuizConfig(quizId,defaultConfig)
}

const monthNameToMonthNumber = {
    January: '1',
    February: '2',
    March: '3',
    April: '4',
    May: '5',
    June: '6',
    July: '7',
    August: '8',
    September: '9',
    October: '10',
    November: '11',
    December: '12'
};



const transformMonthNameToMonthNumber = new MultipleChoice(monthNameToMonthNumber)

export default transformMonthNameToMonthNumber;

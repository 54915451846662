import React from 'react';
import MultipleChoiceQuiz2 from "../../components/MultipleChoiceQuiz2";
import zodiaqSignToSymbolQuiz,{quizId} from "./ZodiaqSignToSymbolQuiz2";
import Lesson from "../../components/Lesson";

//display logic
const zodiaqSignToSymbolQuestionDisplay = (question) => {
   return (<>
      <h4> choose the right symbol for</h4>
      <h1>{question}</h1>
   </>)
}

const zodiaqSignToSymbolAnswerDisplay = (answer) => {
   return <span>{answer}</span>
}

//all the 'quiz' object is passed to the explanation function so we can stay dynamic with what we want to write in explanation
const zodiaqSignToSymbolExplanation = ({correctAnswer}) => {
   return <span>the right symbol is {correctAnswer}</span>
}

const ZodiaqSignToSymbolQuiz = () => {
   return (<MultipleChoiceQuiz2
          quizId={quizId}
          quiz={zodiaqSignToSymbolQuiz}
          questionDisplay={zodiaqSignToSymbolQuestionDisplay}
          answerDisplay={zodiaqSignToSymbolAnswerDisplay}
          explanationDisplay={zodiaqSignToSymbolExplanation}
      />)
}


const ZodiaqSignLesson  = () => <Lesson getNextQuizComponent={() => ZodiaqSignToSymbolQuiz}/>

export default ZodiaqSignLesson;

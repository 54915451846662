import {simpleHash, getRandomInteger, deepEqual,arrayRandomIndex} from '../utils';

const defaultConfig = {
    numberOfOptions: 4,
}

const hashmapMode = { //activate only by passing an object in the first argument, and you cannot pass the next arguments (except optional 'config' argument)
    generateQuestion: (hashmap) => {
        const keys = Object.keys(hashmap);
        const randomIndex = arrayRandomIndex(keys.length);
        return keys[randomIndex];
    },
    solveQuestion: (hashmap,question) => {
        return hashmap[question]
    }

}

export default class MultipleChoice{
    question = {};
    correctAnswer = {};
    answers = [];
    correctAnswerIndex = 0;

    answersHashs = {}; //internal variable for answer uniqueness validation

    constructor(generateQuestion,solveQuestion, generateAnswerOption = null, config = defaultConfig ){
        if(typeof generateQuestion === 'object'){ // hashmap mode
            this.hashmap = generateQuestion;
            this.generateQuestion = hashmapMode.generateQuestion.bind(null, this.hashmap);
            this.solveQuestion = hashmapMode.solveQuestion.bind(null, this.hashmap);
            this.numberOfOptions = config.numberOfOptions;
            this.generateAnswerOption = () => this.solveQuestion(this.generateQuestion())
        }else{ // 'classic' mode
            this.generateQuestion = generateQuestion;
            this.solveQuestion = solveQuestion;
            this.numberOfOptions = config.numberOfOptions;
            if(generateAnswerOption){
                this.generateAnswerOption = generateAnswerOption
            }else{
                this.generateAnswerOption = () => this.solveQuestion(this.generateQuestion())
            }
        }

        this.generateNewQuestion();
    }

    fillAnswers(){
        this.correctAnswerIndex = getRandomInteger(0, this.numberOfOptions - 1 );
        this.answers[this.correctAnswerIndex] = this.correctAnswer;

        for(let i = 0; i < this.numberOfOptions ; i++){
            if(!this.answers[i]){
                //we generate a new question and pass the 'correctAnswer' variable because sometimes we want to generate options based on the correct answer
                // let currentAnswer = this.solveQuestion(this.generateQuestion(this.correctAnswer));
                let currentAnswer = this.generateAnswerOption(this.correctAnswer);
                //check that the answer is unique
                let loopSafetyCounter = 0;
                while(this.answersHashs[simpleHash(currentAnswer)] && loopSafetyCounter < 200){
                    console.log(this.answersHashs[simpleHash(currentAnswer)])
                    console.log(this)
                    currentAnswer = this.generateAnswerOption(this.correctAnswer);
                    loopSafetyCounter++
                }
                this.answers[i] = currentAnswer;
                this.answersHashs[simpleHash(currentAnswer)] = true;
            }
        }

    }
    generateNewQuestion(){
        this.answersHashs = {};
        this.answers = []
        // check it is a different question from the previous one
        let nextQuestion = this.generateQuestion();
        while(deepEqual(this.question,nextQuestion)){
            nextQuestion = this.generateQuestion();
        }
        this.question = nextQuestion;
        this.correctAnswer = this.solveQuestion(this.question);
        this.answersHashs[simpleHash(this.correctAnswer)] = true;
        this.fillAnswers();
    }

}


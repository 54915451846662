import React, {useContext, useEffect, useState} from 'react';
import {ButtonFocus, CheckButton} from "./UI";
import LessonContext from "../lessons/lessonContext";


const USER_CORRECT_MODE = 'userCorrect';
const USER_WRONG_MODE = 'userWrong';
const INITIAL_MODE = 'initial'

const MultipleChoiceQuiz2 = ({ quiz, questionDisplay, answerDisplay, explanationDisplay}) => {
    const [currentQuestion, setCurrentQuestion] = useState(quiz.question)
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [mode, setMode] = useState(INITIAL_MODE)
    const {correctAnswerCounter, setCorrectAnswerCounter, next } = useContext(LessonContext)
    const checkAnswer = () => {
        if(selectedAnswer === quiz.correctAnswerIndex){
            setMode(USER_CORRECT_MODE)
            setCorrectAnswerCounter(correctAnswerCounter + 1)
        }else{
            setMode(USER_WRONG_MODE)
        }

    }

    const proceedToNextQuestion = () => {
        quiz.generateNewQuestion();
        setSelectedAnswer(null)
        setMode(INITIAL_MODE)
        setCurrentQuestion(quiz.question)
        next();
    }

    useEffect(() => { // we have to update the question state after switching component.
        setCurrentQuestion(quiz.question)
    },[quiz])

    const modeClassName = `${mode === USER_CORRECT_MODE ? 'user-correct' : ''} ${mode === USER_WRONG_MODE ? 'user-wrong' : ''}`

    return (
        <>
            <main>
                {questionDisplay(currentQuestion)}
                    {
                        quiz.answers.map((answer, index) => <ButtonFocus key={index}
                                                                         className={selectedAnswer === index ? 'focus-like' : ''}
                                                                         onClick={() => setSelectedAnswer(index)}>{answerDisplay(answer)}</ButtonFocus>)
                    }
            </main>
            <footer className={modeClassName}>
                <CheckButton disabled={(selectedAnswer === null)} className={modeClassName}
                             onClick={mode === INITIAL_MODE ? checkAnswer : proceedToNextQuestion}>{mode === INITIAL_MODE ? 'CHECK' : 'CONTINUE'}</CheckButton>
                {mode === USER_WRONG_MODE && <h2>{explanationDisplay(quiz)}</h2>}
                {mode === USER_CORRECT_MODE && <h2>correct!</h2>}
            </footer>
        </>
    );
}

export default MultipleChoiceQuiz2;

import React, { useState } from "react";
import QuizConfig, {OpenConfigMenuButton} from "./QuizConfig";
import styled from "styled-components";
import NavigateToHomePageButton from "./NavigateToHomePageButton";
import LessonContext from "../lessons/lessonContext";

const LessonStyle = styled.section`
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-columns: 100%;
    //grid-template-rows: 1fr 8fr 2fr;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 460px;
    overflow: hidden;
    position: absolute;

    header{
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        padding-block-start: 10px;
        padding-block-end: 0;
        mark{
            font-size: 50px;
            background-color: initial;
            color: rgb(89 167 0);
        }
    }
    main{
        justify-self: center;
        max-width: 328px;

        h1{
            display: block;
        }
        button{
            font-size: 23px;
            width: 160px;
            height: 160px;

        }
        
    }
    footer{
        height: 140px;
        width: 100%;
        border-block-start: 2px solid rgb(229,229,229);
        justify-self: end;
        padding: 40px;// todo: make the alignment of the footer buttons work well
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        &.user-correct{
            background-color: rgb(215 255 184);
            color: rgb(89 167 0);
            border-block-start: none;
            
        }
        &.user-wrong{
            background-color: rgb(255 223 224);
            color: rgb(234 42 42);
            border-block-start: none;

        }
    }
`


const Lesson = ({ getNextQuizComponent }) => {

    const [QuizComponent, setQuizComponent] = useState(getNextQuizComponent())

    const [correctAnswerCounter,setCorrectAnswerCounter] = useState(0);
    const next = () => {
        let nextQuizComponent = getNextQuizComponent()
        if(QuizComponent !== nextQuizComponent){
            setQuizComponent( nextQuizComponent )
        }
    }


    return (
        <LessonContext.Provider value={{correctAnswerCounter,setCorrectAnswerCounter,next}}>
            <LessonStyle>
                <div id="modal" popover="auto">
                    <QuizConfig quizId={QuizComponent?.props?.quizId || QuizComponent?.props?.children?.props?.quizId}/>
                </div>
                <header>
                    <NavigateToHomePageButton/>
                    <mark>{correctAnswerCounter}</mark>
                    <OpenConfigMenuButton popovertarget="modal"></OpenConfigMenuButton>
                </header>
                { QuizComponent }
            </LessonStyle>
        </LessonContext.Provider>
    )
}

export default Lesson;

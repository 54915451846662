import {getRandomElementFromArray, getRandomInteger} from "../../utils";
import MultipleChoice from "../../classes/MultipleChoice";

export const  quizId = 'yrtcntry-mltplchce'

const generateYearToCenturyQuestion = ( ) => {
    const year = getRandomInteger(1,2024)
    const era = getRandomElementFromArray(['AD','BC'])
    return {year,era}
}
const solveYearToCenturyQuestion = ({year, era}) => {
    let century = 0;
    if (era === "AD") {
        // For AD, divide the year by 100 and round up if there is any remainder
        century = Math.ceil(year / 100);
    } else if (era === "BC") {
        // For BC, take the absolute value, divide by 100 and round up
        century =  Math.ceil(Math.abs(year) / 100);
    } else {
        // If the era is not recognized, return an error or a zero
        century = 0;
    }
    return {century, era}
}
// generateAnswerOption is an optional function - when there is a answer generation logic that depends on the correct answer, or that is not supposed to be some answer that you can get from a different question in the game
const generateAnswerOption = (correctAnswer) => {
        // Calculate the minimum and maximum possible centuries
        const minCentury = correctAnswer.century - 3;
        const maxCentury = correctAnswer.century + 3;

        // Generate a random century in the range from minCentury to maxCentury
        let randomCentury = Math.floor(Math.random() * (maxCentury - minCentury + 1)) + minCentury;
        if(randomCentury < 1) randomCentury = 1;
        return {century: randomCentury, era: correctAnswer.era};
}

const yearToCentury = new MultipleChoice(generateYearToCenturyQuestion,solveYearToCenturyQuestion,generateAnswerOption)

export default yearToCentury

import React from 'react';
import MultipleChoiceQuiz2 from "../../components/MultipleChoiceQuiz2";
import transformMonthNameToMonthNumber , {quizId} from "./monthNameToMonthNumberQuiz.js";
import Lesson from "../../components/Lesson";


//display logic
const monthNameToNumberQuestionDisplay = (question) => {
    return (<>
        <h4>what is the number of the month: </h4>
        <h1>{question}</h1>
    </>)
}

const monthNameToNumberAnswerDisplay = (answer) => {
    return <span>{answer}</span>
}

//all the 'quiz' object is passed to the explanation function so we can stay dynamic with what we want to write in explanation
const monthNameToNumberExplanationDisplay = ({correctAnswer}) => {
    return <span>the correct answer is {correctAnswer}</span>
}

const MonthNameToMonthNumberQuiz = () => {

    return <>
        <MultipleChoiceQuiz2
            quizId={quizId}
            quiz={transformMonthNameToMonthNumber}
            questionDisplay={monthNameToNumberQuestionDisplay}
            answerDisplay={monthNameToNumberAnswerDisplay}
            explanationDisplay={monthNameToNumberExplanationDisplay}
        />
    </>
}

const MonthNameToMonthNumberLesson = () => <Lesson getNextQuizComponent={() => MonthNameToMonthNumberQuiz} ></Lesson>

export default MonthNameToMonthNumberLesson;

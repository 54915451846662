import React from 'react';
import MultipleChoiceQuiz2 from "../../components/MultipleChoiceQuiz2";
import baseConversionQuiz, {quizId} from './numberBaseConversionQuiz.js';
import {baseNames, basePrefixes} from "../../utils";
import styled from "styled-components";
import StorageService from "../../services/storageService";
const StyledSub = styled.sub`
        display: inline-block;
        font-size: 13px;
        font-weight: bold;
        color: #696565;
        transform: translateX(2px);
`

const BaseNumberName = (radix) => {
    const config = StorageService.getQuizConfig(quizId)
    if(config.numberDisplayStyle.selectedOption === 'programming'){
        return <>{baseNames[radix + '']}</>
    }
    return <>base-<span>{radix}</span></>
}

const RadixNumberDisplay = (number, radix) => {
    const config = StorageService.getQuizConfig(quizId)
    if(config.numberDisplayStyle.selectedOption === 'programming'){
        return <span>{basePrefixes[radix+'']? basePrefixes[radix+''] : ''}{number}</span>
    }
    if(radix == '10'){
        return parseInt(number, 10).toLocaleString();
    }else {
        return (<><span>{number}</span>
        <StyledSub>{radix}</StyledSub></>)
    }
}

const baseConversionQuestionDisplay = (question) => {
    const {number, fromRadix, toRadix} = question;
    return <div>
        <h3>convert the following number to {BaseNumberName(toRadix)}:</h3>
        <h1>{RadixNumberDisplay(number,fromRadix)}</h1>
    </div>
}

const baseConversionAnswerDisplay = (answer) => {
    const {convertedNumber, toRadix} = answer;
    return <p>{RadixNumberDisplay(convertedNumber,toRadix)}</p>
}

const baseConversionExplanationDisplay = ({correctAnswer}) => {
    const {convertedNumber, toRadix } = correctAnswer;
    return <span>the correct answer is {RadixNumberDisplay(convertedNumber,toRadix)}</span>
}

const BaseConversionQuiz = () => {
    return (<MultipleChoiceQuiz2
            quizId={quizId}
            quiz={baseConversionQuiz}
            questionDisplay={baseConversionQuestionDisplay}
            answerDisplay={baseConversionAnswerDisplay}
            explanationDisplay={baseConversionExplanationDisplay}
        />)
}

BaseConversionQuiz.ID = quizId;

export default BaseConversionQuiz;

import React from 'react';
import MultipleChoiceQuiz2 from "../../components/MultipleChoiceQuiz2";
import exponentiation, {quizId} from "./exponentsQuiz.js";


//display logic
const exponentQuestionDisplay = (question) => {
    return <h1>what is the value of <span>{question.base}</span><sup>{question.exponent}</sup></h1>
}

const exponentAnswerDisplay = (answer) => {
    return <span>{answer.power.toLocaleString()}</span>
}

//all the 'quiz' object is passed to the explanation function so we can stay dynamic with what we want to write in explanation
const exponentExplanationDisplay = ({correctAnswer}) => {
    return <span>the correct answer is {correctAnswer.power.toLocaleString()}</span>
}

const ExponentsQuiz = () => {

    return <>
        <MultipleChoiceQuiz2
            quizId={quizId}
            quiz={exponentiation}
            questionDisplay={exponentQuestionDisplay}
            answerDisplay={exponentAnswerDisplay}
            explanationDisplay={exponentExplanationDisplay}
        />
    </>
}

export default ExponentsQuiz;

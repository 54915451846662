import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '../components/UI';
import monkey from '../assets/positional-number-system-monkey.webp'

const HomePage = styled.div`
    display: grid;
    justify-items: center;
    
    img{
        width: 294px;
    }
    nav {
        order: 1;
        button{
            display: block;
            width: 300px;
            margin: 23px 0;
            padding: 20px;
        }
        a{
            display: block;
            color: inherit;
            text-decoration: initial;
        }
    }
`

function PostionalNumberSystems() {
  return (
      <HomePage>
          <img src={monkey} alt="red-handed tamarin positional number system"/>
          <nav>
              <Link to="/exponent-2">
                  <PrimaryButton>
                      Exponents of 2
                  </PrimaryButton>
              </Link>
              <Link to="/base-conversion">
                  <PrimaryButton>
                      Base Conversions
                  </PrimaryButton>
              </Link>
          </nav>
          <main>
              <h1>Positional Number Systems</h1>
          </main>
      </HomePage>
  );
}

export default PostionalNumberSystems;

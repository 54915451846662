
const setItem = (key,value) => {
    return localStorage.setItem(key, JSON.stringify(value));
}

const getItem = (key) => {
    const storedObject = localStorage.getItem(key);
    return storedObject ? JSON.parse(storedObject) : null;
}

const setQuizConfig = (quizId, config) => {
    return setItem(quizId + '-config', config);
}

const getQuizConfig = (quizId) => {
    return getItem(quizId + '-config')
}

export default {
    setQuizConfig,
    getQuizConfig,
}

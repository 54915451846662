 export function deepEqual(a, b) {
      // Check if both are the same reference or both are NaN (NaN !== NaN)
      if (a === b || (Number.isNaN(a) && Number.isNaN(b))) {
          return true;
      }

      // If types are different, return false
      if (typeof a !== typeof b) {
          return false;
      }

      // If they are functions, convert to string and compare
      // Note: This is a simplistic check and might not work for all cases.
      if (typeof a === 'function') {
          return a.toString() === b.toString();
      }

      // If they are not objects (including null), return false
      if (typeof a !== 'object' || a === null || b === null) {
          return false;
      }

      // Check if both variables are arrays and compare
      if (Array.isArray(a) && Array.isArray(b)) {
          if (a.length !== b.length) return false;
          for (let i = 0; i < a.length; i++) {
              if (!deepEqual(a[i], b[i])) return false;
          }
          return true;
      }

      // If both are objects, check keys length and then each key/value
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(b);
      if (aKeys.length !== bKeys.length) {
          return false;
      }
      for (let key of aKeys) {
          if (!b.hasOwnProperty(key) || !deepEqual(a[key], b[key])) {
              return false;
          }
      }
      return true;
  }
 export function generateRandomBaseNumber(radix, numDigits) {
     if (radix < 2 || radix > 64) {
         throw new Error('Radix must be between 2 and 64');
     }

     const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+/";

     // Calculate the minimum and maximum values for the given number of digits
     let minValue = Math.pow(radix, numDigits - 1);
     let maxValue = Math.pow(radix, numDigits) - 1;

     // Adjust maximum value based on JavaScript's number limit
     maxValue = Math.min(maxValue, Number.MAX_SAFE_INTEGER);

     // Generate a random number in the specified range
     let randomValue = Math.floor(Math.random() * (maxValue - minValue + 1) + minValue);

     // Convert the number to the specified radix (base)
     let result = '';
     while (randomValue > 0) {
         result = characters.charAt(randomValue % radix) + result;
         randomValue = Math.floor(randomValue / radix);
     }

     // Ensure the result has at least numDigits length by padding with zeros (using the first character in the set)
     while (result.length < numDigits) {
         result = characters.charAt(0) + result;
     }

     return result;
 }

 export function convertBase(numberStr, fromRadix, toRadix) {
     if (fromRadix < 2 || fromRadix > 64 || toRadix < 2 || toRadix > 64) {
         throw new Error('Radix must be between 2 and 64');
     }

     const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+/";

     // Parse the input number from the given fromRadix
     let decimalValue = 0;
     for (let i = 0; i < numberStr.length; i++) {
         decimalValue = decimalValue * fromRadix + characters.indexOf(numberStr.charAt(i));
     }

     // Handle conversion to decimal
     if (toRadix === 10) {
         return decimalValue.toString();
     }

     // Convert the decimalValue to the target toRadix
     let result = '';
     while (decimalValue > 0) {
         result = characters.charAt(decimalValue % toRadix) + result;
         decimalValue = Math.floor(decimalValue / toRadix);
     }

     // Handle zero case
     return result || "0";
 }

 export const arrayRandomIndex = (arrayLength) =>  Math.floor(Math.random() * arrayLength);


 export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        // Generate a random index from 0 to i
        let j = Math.floor(Math.random() * (i + 1));

        // Swap elements at indices i and j
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  export function formatRationalNumberIfNecessary(number) {
      // Convert the number to a string to check its parts
      const numberStr = number.toString();

      // Check if the number is rational (has a decimal point) and has more than 2 digits after the decimal
      const [integerPart, fractionalPart] = numberStr.split('.');
      if (fractionalPart && fractionalPart.length > 2) {
          // Number is rational and has more than 2 digits after the decimal, so format it
          return number.toFixed(2);
      } else {
          // Number is either an integer or has 2 or fewer digits after the decimal, so return it unchanged
          // Note: Converting back to number to maintain the type as input
          return number;
      }
  }

  export function generateSimilarNumberWithDifferentDigits(numberInput) {
      // Convert the input to a string to handle it uniformly

      const numberStr = numberInput.toString();

      // Split the input number into integer and fractional parts
      let [integerPart, fractionalPart] = numberStr.split('.');

      // Function to generate a random digit different from the current one
      function randomDifferentDigit(currentDigit) {
          let digit = Math.floor(Math.random() * 10).toString();
          while (digit === currentDigit) {
              digit = Math.floor(Math.random() * 10).toString();
          }
          return digit;
      }

      // Process the integer part
      let newIntegerPart = '';
      for (let digit of integerPart) {
          newIntegerPart += randomDifferentDigit(digit);
      }

      // Process the fractional part if it exists
      let newFractionalPart = '';
      if (fractionalPart) {
          for (let digit of fractionalPart) {
              newFractionalPart += randomDifferentDigit(digit);
          }
      }

      // Combine the new integer and fractional parts
      return newFractionalPart ? Number(`${newIntegerPart}.${newFractionalPart}`) : Number(newIntegerPart);
  }

  export function simpleHash(obj) {
      let str = JSON.stringify(obj);
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
          const char = str.charCodeAt(i);
          hash = ((hash << 5) - hash) + char;
          hash |= 0; // Convert to 32bit integer
      }
      return hash.toString();
  }

export const getRandomInteger = (min, max) => {
    // Ensure the inputs are treated as integers
    min = Math.ceil(min);
    max = Math.floor(max);
    // The maximum is inclusive and the minimum is inclusive
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getRandomElementFromArray = (array) => {
    if (array.length === 0) {
        return null;  // Return null if the array is empty
    }
    const index = Math.floor(Math.random() * array.length);  // Generate a random index
    return array[index];  // Return the element at the random index
}

 export function numberToOrdinal(n) {
     // Check for 11-13 because those are special cases
     if (n % 100 >= 11 && n % 100 <= 13) {
         return n + 'th';
     }

     switch (n % 10) {
         case 1: return n + 'st';
         case 2: return n + 'nd';
         case 3: return n + 'rd';
         default: return n + 'th';
     }
 }


 export const baseNames = {
     "2": "Binary",
     "3": "Ternary",
     "4": "Quaternary",
     "5": "Quinary",
     "6": "Senary",
     "7": "Septenary",
     "8": "Octal",
     "9": "Nonary",
     "10": "Decimal",
     "11": "Undecimal",
     "12": "Duodecimal",
     "13": "Tridecimal",
     "14": "Tetradecimal",
     "15": "Pentadecimal",
     "16": "Hexadecimal",
     "17": "Base-17",
     "18": "Octodecimal",
     "19": "Base-19",
     "20": "Vigesimal",
     "21": "Unvigesimal",
     "22": "Duovigesimal",
     "23": "Trivigesimal",
     "24": "Tetravigesimal",
     "25": "Pentavigesimal",
     "26": "Hexavigesimal",
     "27": "Septemvigesimal",
     "28": "Octovigesimal",
     "29": "Nonavigesimal",
     "30": "Trigesimal",
     "31": "Untrigesimal",
     "32": "Duotrigesimal",
     "33": "Base-33",
     "34": "Base-34",
     "35": "Base-35",
     "36": "Hexatrigesimal",
     "37": "Base-37",
     "38": "Base-38",
     "39": "Base-39",
     "40": "Quadragesimal",
     "41": "Base-41",
     "42": "Base-42",
     "43": "Base-43",
     "44": "Base-44",
     "45": "Base-45",
     "46": "Base-46",
     "47": "Base-47",
     "48": "Base-48",
     "49": "Base-49",
     "50": "Base-50",
     "51": "Base-51",
     "52": "Base-52",
     "53": "Base-53",
     "54": "Base-54",
     "55": "Base-55",
     "56": "Base-56",
     "57": "Base-57",
     "58": "Base-58",
     "59": "Base-59",
     "60": "Base-60",
     "61": "Base-61",
     "62": "Base-62",
     "63": "Base-63",
     "64": "Base-64"
 };

 export const basePrefixes = {
     "2": "0b",   // Prefix for binary numbers
     "8": "0o",   // Prefix for octal numbers
     "16": "0x"   // Prefix for hexadecimal numbers
 };

import StorageService from "../../services/storageService";
import {getRandomInteger} from "../../utils";
import MultipleChoice from "../../classes/MultipleChoice";

export const quizId = 'expts-mltplchce'; //magic string

const defaultConfig = {
    baseMin: 2,
    baseMax: 2,
    minExponent: 0,
    maxExponent: 10
}

if(!StorageService.getQuizConfig(quizId)){ //initialize config if it's not existing in storage
    StorageService.setQuizConfig(quizId,defaultConfig)
}

const generateExponent2Question = ( ) => {
    let config = StorageService.getQuizConfig(quizId) || defaultConfig;
    return {base: getRandomInteger(config.baseMin , config.baseMax), exponent: getRandomInteger(config.minExponent , config.maxExponent)}
}
const solveExponentialQuestion = ( question ) => {
    return {power: question.base ** question.exponent};
}

const exponentiation = new MultipleChoice(generateExponent2Question,solveExponentialQuestion)

export default exponentiation;

import alpha from '../../assets/greekLettersSounds/alpha.m4a'
import beta from '../../assets/greekLettersSounds/beta.m4a'
import gamma from '../../assets/greekLettersSounds/gamma.m4a'
import delta from '../../assets/greekLettersSounds/delta.m4a'
import epsilon from '../../assets/greekLettersSounds/epsilon.m4a'
import zeta from '../../assets/greekLettersSounds/zeta.m4a'
import eta from '../../assets/greekLettersSounds/eta.m4a'
import theta from '../../assets/greekLettersSounds/theta.m4a'
import iota from '../../assets/greekLettersSounds/iota.m4a'
import kappa from '../../assets/greekLettersSounds/kappa.m4a'
import lambda from '../../assets/greekLettersSounds/lambda.m4a'
import mu from '../../assets/greekLettersSounds/mu.m4a'
import nu from '../../assets/greekLettersSounds/nu.m4a'
import xi from '../../assets/greekLettersSounds/xi.m4a'
import omicron from '../../assets/greekLettersSounds/omicron.m4a'
import pi from '../../assets/greekLettersSounds/pi.m4a'
import rho from '../../assets/greekLettersSounds/rho.m4a'
import sigma from '../../assets/greekLettersSounds/sigma.m4a'
import tau from '../../assets/greekLettersSounds/tau.m4a'
import upsilon from '../../assets/greekLettersSounds/upsilon.m4a'
import phi from '../../assets/greekLettersSounds/phi.m4a'
import chi from '../../assets/greekLettersSounds/chi.m4a'
import psi from '../../assets/greekLettersSounds/psi.m4a'
import omega from '../../assets/greekLettersSounds/omega.m4a'

export const greekAlphabetNameToCapitalLetterMap = {
    Alpha: 'Α',
    Beta: 'Β',
    Gamma: 'Γ',
    Delta: 'Δ',
    Epsilon: 'Ε',
    Zeta: 'Ζ',
    Eta: 'Η',
    Theta: 'Θ',
    Iota: 'Ι',
    Kappa: 'Κ',
    Lambda: 'Λ',
    Mu: 'Μ',
    Nu: 'Ν',
    Xi: 'Ξ',
    Omicron: 'Ο',
    Pi: 'Π',
    Rho: 'Ρ',
    Sigma: 'Σ',
    Tau: 'Τ',
    Upsilon: 'Υ',
    Phi: 'Φ',
    Chi: 'Χ',
    Psi: 'Ψ',
    Omega: 'Ω'
};

export const greekLettersUpperCaseToLowerCaseMapping = {
    Α: 'α', // Alpha
    Β: 'β', // Beta
    Γ: 'γ', // Gamma
    Δ: 'δ', // Delta
    Ε: 'ε', // Epsilon
    Ζ: 'ζ', // Zeta
    Η: 'η', // Eta
    Θ: 'θ', // Theta
    Ι: 'ι', // Iota
    Κ: 'κ', // Kappa
    Λ: 'λ', // Lambda
    Μ: 'μ', // Mu
    Ν: 'ν', // Nu
    Ξ: 'ξ', // Xi
    Ο: 'ο', // Omicron
    Π: 'π', // Pi
    Ρ: 'ρ', // Rho
    Σ: 'σ', // Sigma (final sigma has its own lowercase form, ς, used at the end of words)
    Τ: 'τ', // Tau
    Υ: 'υ', // Upsilon
    Φ: 'φ', // Phi
    Χ: 'χ', // Chi
    Ψ: 'ψ', // Psi
    Ω: 'ω'  // Omega
};


export const greekAlphabetLetterToSoundUrlMap = {
    Alpha: alpha,
    Α: alpha,
    α: alpha,
    Beta: beta,
    Β: beta,
    β: beta,
    Gamma: gamma,
    Γ: gamma,
    γ: gamma,
    Delta: delta,
    Δ: delta,
    δ: delta,
    Epsilon: epsilon,
    Ε: epsilon,
    ε: epsilon,
    Zeta: zeta,
    Ζ: zeta,
    ζ: zeta,
    Eta: eta,
    Η: eta,
    η: eta,
    Theta: theta,
    Θ: theta,
    θ: theta,
    Iota: iota,
    Ι: iota,
    ι: iota,
    Kappa: kappa,
    Κ: kappa,
    κ: kappa,
    Lambda: lambda,
    Λ: lambda,
    λ: lambda,
    Mu: mu,
    Μ: mu,
    μ: mu,
    Nu: nu,
    Ν: nu,
    ν:nu,
    Xi: xi,
    Ξ: xi,
    ξ: xi,
    Omicron: omicron,
    Ο: omicron,
    ο: omicron,
    Pi: pi,
    Π: pi,
    π: pi,
    Rho: rho,
    Ρ: rho,
    ρ: rho,
    Sigma: sigma,
    Σ: sigma,
    σ: sigma,
    ς: sigma, //final sigma, at the end of words
    Tau: tau,
    Τ: tau,
    τ: tau,
    Upsilon: upsilon,
    Υ: upsilon,
    υ: upsilon,
    Phi: phi,
    Φ: phi,
    φ: phi,
    Chi: chi,
    Χ: chi,
    χ: chi,
    Psi: psi,
    Ψ: psi,
    ψ: psi,
    Omega: omega,
    Ω: omega,
    ω: omega,
};

import StorageService from "../../services/storageService";
import MultipleChoice from "../../classes/MultipleChoice";

const zodiacSignToEmoji = {
    Aries: '♈',
    Taurus: '♉',
    Gemini: '♊',
    Cancer: '♋',
    Leo: '♌',
    Virgo: '♍',
    Libra: '♎',
    Scorpio: '♏',
    Sagittarius: '♐',
    Capricorn: '♑',
    Aquarius: '♒',
    Pisces: '♓'
};


export const quizId = 'zdqsgntsymbl-mltplchce'; //magic string

const defaultConfig = {
    symbolCount: 24, // not doing anything right now
}

if(!StorageService.getQuizConfig(quizId)){ //initialize config if it's not existing in storage
    StorageService.setQuizConfig(quizId,defaultConfig)
}

const zodiaqSignToSymbolQuiz = new MultipleChoice(zodiacSignToEmoji)

export default zodiaqSignToSymbolQuiz;
